@font-face {
  font-family: "GT-Alpina";
  src: url("./system/assets/fonts/GT-Alpina/GT-Alpina-Condensed-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GT-Alpina";
  src: url("./system/assets/fonts/GT-Alpina/GT-Alpina-Condensed-Bold-Italic.ttf");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "mabry";
  src: url("./system/assets/fonts/mabry/mabry-bold-pro.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "mabry";
  src: url("./system/assets/fonts/mabry/mabry-regular-pro.ttf");
  font-weight: normal;
  font-style: normal;
}

body,
textarea {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root {
  background: var(--palette-background-default);
  height: 100%;
  font-size: 16px;
}

#root {
  display: flex;
  flex-direction: column;
}

a {
  color: var(--palette-text-main);
  text-decoration: none;
}

a:hover {
  color: var(--palette-primary-main);
}
