/* Print */
.print-source {
  display: none;
}

body > .print-source {
  display: block;
}

@media print {
  .print-source {
    display: block;
  }
}

/* Effects */
.shimmer {
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}
.box {
  --thickness: 2px; /* thickness of the border */
  --color: var(--palette-primary-main); /* color of the border */
  --width: 32px; /* width of border */

  border: var(--thickness) solid #0000; /* space for the border */
  --_g: #0000 90deg, var(--color) 0;
  --_p: var(--width) var(--width) border-box no-repeat;
  background: conic-gradient(from 90deg at top var(--thickness) left var(--thickness), var(--_g)) 0
      0 / var(--_p),
    conic-gradient(from 180deg at top var(--thickness) right var(--thickness), var(--_g)) 100% 0 /
      var(--_p),
    conic-gradient(from 0deg at bottom var(--thickness) left var(--thickness), var(--_g)) 0 100% /
      var(--_p),
    conic-gradient(from -90deg at bottom var(--thickness) right var(--thickness), var(--_g)) 100%
      100% / var(--_p);
}
